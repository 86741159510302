import styled from "@emotion/styled";
export const StyledVerticalTimerTabComponent = styled.div`
  margin-bottom: 32px;
  height: 32px;

  .vertical-timer-tab-desktop-title-button {
    display: flex;
    cursor: pointer;
    line-height: 16px;
    font-weight: 300;
    padding: 0;

    :hover {
      outline: 0;
    }

    .vertical-timer-tab-title-badge-margin {
      display: inline;
      margin-left: 8px;
    }

    .vertical-timer-tab-arrow-wrapper {
      flex-grow: 1;
      text-align: end;
      display: flex;
      justify-content: flex-end;

      .vertical-timer-tab-arrow {
        width: 22px;
        transition: 0.3s transform ease-in-out;

        &.selected {
          transform: rotate(180deg);
        }
      }
    }
  }

  .vertical-timer-tab-desktop-title-button:hover {
    font-weight: 500;
  }

  .vertical-timer-tab-desktop-title-button-selected {
    font-weight: 400;
    margin-bottom: 16px;
  }

  .vertical-timer-tab-desktop-progress-container {
  }

  .vertical-timer-tab-desktop-content-container {
    overflow: hidden;
    transition-duration: 0.15s;
    height: 0;
    opacity: 0;
    transition: opacity 0.3s linear;
  }

  .vertical-timer-tab-desktop-content-container-selected {
    opacity: 1;
    height: 100%;
  }

  .vertical-timer-tab-desktop-content {
    font-size: 0.875rem;
    line-height: 22px;
    margin-top: 8px;
  }

  &.vertical-timer-tab-desktop-container-selected {
    height: auto !important;
  }
`;
